<template>
  <v-app>
    <v-app-bar app color="brown darken-1" dark dense>
      <div class="d-flex align-center">
        <v-avatar size="30" color="white">
          <v-img :src="require('./assets/LOGO-sm.jpg')" alt="logo" />
        </v-avatar>

        <div class="ml-3 text-h6">時睦珈琲｜Coffee</div>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <BottomNavigation />
  </v-app>
</template>

<script>
import BottomNavigation from "./components/BottomNavigation";
export default {
  name: "App",
  components: { BottomNavigation },
  data: () => ({
    //
  })
};
</script>
