<template>
  <div class="mb-12">
    <Banner />
    
    <v-container class="pa-6">
      <v-row align="center">
        <v-col sm="12" md="6">
          <div
            class="text-h5 mb-2 brown--text text--darken-1 text-center font-weight-bold"
          >時睦咖啡 X 中山168</div>
          <div class="text-center">讓咖啡帶來美好氛圍</div>
          <div class="text-center">欣賞藝術少女的巧思</div>
          <div class="text-center">體驗在地故事的感動</div>
        </v-col>
        <v-col sm="12" md="6">
          <v-img :src="require('../assets/menu.jpg')" alt="menu"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <ProductList />
  </div>
</template>

<script>
import Banner from "../components/Banner";
import ProductList from "../components/ProductList";
export default {
  name: "Home",
  components: { Banner, ProductList },
  data: () => ({ value: 1 })
};
</script>
