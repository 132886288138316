<template>
  <!-- <v-container fluid> -->
  <v-parallax dark :src="require('../assets/banner1.jpg')" height="700" >
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="12">
        <div class="mt-16"></div>
        <v-avatar size="150" color="white" class="">
          <v-img :src="require('../assets/LOGO-sm.jpg')" alt="logo" />
        </v-avatar>
        <h1 class="text-h4 font-weight-black mt-8 mb-2">時睦珈琲｜Coffee</h1>
        <h2 class="subheading mb-16">自家烘培精品咖啡工作室</h2>
        <h4 class="subheading mb-1 white--text text--darken-1">作物生長需要大自然滋養</h4>
        <h4 class="subheading mb-1 white--text text--darken-1">時節和順，萬物豐睦。</h4>
        <h4 class="white--text text--darken-1">從咖啡出發，願你我都有美好豐盛的生活</h4>
      </v-col>
    </v-row>
  </v-parallax>
  <!-- </v-container> -->
</template>

<script>
export default {
  name: "Banner",
  data: () => ({})
};
</script>

