<template>
  <v-bottom-navigation fixed color="brown">
    <v-btn href="https://www.facebook.com/coffeeharmonytw" target="_blank" text icon class="my-auto">
      <span>Facebook</span>
      <v-icon>mdi-facebook</v-icon>
    </v-btn>
    <v-btn href="https://line.me/R/ti/p/@074pqvdn" target="_blank" text icon class="my-auto">
      <span>LINE</span>
      <v-icon>mdi-heart</v-icon>
    </v-btn>
    <v-btn href="https://famishop.fami.life/id/coffeeharmonytw" target="_blank" text icon class="my-auto">
      <span>賣場</span>
      <v-icon>mdi-store</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "BottomNavigation",
  data: () => ({
    value:'recent'
  }),
  computed: {
  }
};
</script>
<style scoped>
</style>
