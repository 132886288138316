<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" sm="6" md="3" xl="3" v-for="product in products" :key="product.id">
        <v-card class="ma-3">
          <v-img
            dark
            :src="require(`../assets/${product.img}`)"
            height="200"
            gradient="to top right, rgba(109, 76, 65,.45), rgba(109, 76, 65,.55)"
          >
            <v-container fill-height>
              <v-row align="center" justify="center">
                <v-col class="text-center" cols="12">
                  <div class="text-h5 font-weight-black">{{product.name}}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-img>
          <v-card-text>
            <div class="text-center">
              <div
                class="text-subtitle-2 subheading mb-1 brown--text text--darken-1"
              >風味短評：{{product.flavor}}</div>
              <div class="mt-2">
                <v-btn text href="https://famishop.fami.life/id/coffeeharmonytw" target="_blank">
                  <v-icon left>mdi-store</v-icon>前往購買
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProductList",
  data: () => ({
    products: [
      {
        id: "p01",
        name: "衣索比亞｜日曬｜西達摩｜桃可可｜G1",
        flavor: "蜜桃，藍莓，佛手柑",
        img: "cf-d.jpeg"
      },
      {
        id: "p02",
        name: "哥斯大黎加｜西部谷地｜葉爾莎處理廠｜黃蜜處理",
        flavor: "小紅莓，紅糖，香草花卉",
        img: "cf-w.jpeg"
      },

      {
        id: "p03",
        name: "衣索比亞｜日曬｜古吉｜塔莎亞/紫風鈴｜G1｜21/02｜批次",
        flavor: "葡萄，白桃，藍莓果醬",
        img: "cf-d.jpeg"
      },
      {
        id: "p04",
        name: "瓜地馬拉｜安提瓜｜花神｜經典｜特規版",
        flavor: "甜橙，紅糖，草本花卉",
        img: "cf-w.jpeg"
      },
      {
        id: "p05",
        name: "衣索比亞｜水洗｜古吉｜雪風鈴｜G1｜21/01｜批次",
        flavor: "檸檬，杏桃，白花香氣",
        img: "cf-d.jpeg"
      },
      {
        id: "p06",
        name:
          "巴西｜摩吉安娜產區｜COE｜冠軍莊園｜皇后莊園｜100%黃波旁｜日曬處理",
        flavor: "太妃糖，堅果，烤杏仁",
        img: "cf-w.jpeg"
      }
    ]
  }),
  methods: {
    // updateCart() {
    //   const vm = this;
    //   let cart = vm.$store.state.cart;
    //   cart.push({});
    //   console.log(vm.$store.state.cart);
    //   vm.$store.commit("setCart", cart);
    // }
  }
};
</script>
